<script>
export default {
	inject: ['manager'],
}
</script>

<template>
	<div class="product-page__tags">
		<div
			v-for="({ html, style }, n) in manager.tags"
			:key="n"
			v-html="html"
			class="my-2 py-1 px-2 rounded"
			:style="style"
			style="width: fit-content"
		></div>
	</div>
</template>

